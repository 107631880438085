.react-timerange-picker .react-timerange-picker__wrapper {
  /* display: none !important; */
  height: 40px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  justify-content: space-around;
}

.react-timerange-picker
  .react-timerange-picker__wrapper
  .react-timerange-picker__inputGroup {
  width: 100px;
  flex-grow: 0;
  min-width: auto;
}

.img-100 {
  width: 100%;
}
.rbc-header span {
  text-transform: capitalize;
}

.react-timerange-picker .react-timerange-picker__wrapper .react-timerange-picker__inputGroup {
  width: 110px !important;
  
}
